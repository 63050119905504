<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4 class="text-center">
          <i class="fa fa-user-plus"></i>
          انشاء اولياء الامور تلقائياً
        </h4>
        <strong class="text-danger text-center"
          >(للطلاب التي ليس لديها ولي أمر)</strong
        >
      </div>
      <div class="card-body">
        <div class="col-12 text-center g">
          <button class="btn btn-secondary" v-if="loading">
            <i class="fa fa-clock"></i> جاري التحميل...
          </button>
          <button class="btn btn-primary" v-if="!loading" @click="doa()">
            انشاء الآن <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (!checkPer("students-add")) {
      this.$router.push("/per");
      return false;
    }
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    doa() {
      this.loading = true;
      var g = this;
      $.post(api + "/user/students/auto-parents", {
        jwt: g.user.jwt,
      })
        .then(function (r) {
          alert("تم تحديث بيانات " + r.response + " طالب بنجاح");
          location.reload();
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
        });
    },
  },
};
</script>